/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;500;600;700;800&display=swap"); */

/* @font-face {
  font-family: gessmedium;
  src: url("../public/gess_medium.otf");
}

@font-face {
  font-family: gesslight;
  src: url("../public/ArbFONTS-GE_SS_TEXT_LIGHT.otf");
} */

html {
  height: 100%;
}
body {
  font-family: "Cairo";
  /* font-family: 'gessmedium', sans-serif; */
  /* padding-right: 0px !important; */
  height: 100%;
}
.nav{
  overflow: hidden;
}
.iframeOut {
  margin: 0;
}
.iframe-payment .iframBody {
  width: 100%;
}

@media (min-width: 1400px) {
  .MuiContainer-root {
    max-width: 90% !important;
  }
}

.serv .slick-slide > div {
  margin: 0 10px;
}
.serv .slick-list {
  margin: 0 -10px;
}

.App {
  /* margin-top: 130px; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

ul {
  list-style: none;
}

.slick-slide div {
  outline: none !important;
  border: none;
}

.global-slick .slick-list {
  margin: 8px;
}
.global-slick .slick-slide {
  margin: 8px;
}

/* outline btn */
.main-outline-btn {
  position: relative;
  text-align: center;
  width: fit-content;
  padding: 20px;
  background-color: transparent;
  border: none;
  font-size: 18px;
}
/* .main-outline-btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px; */
  /* background: linear-gradient(90.41deg, #f5841f 0.35%, #ed1c24 99.64%); */
  /* -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  display: flex;
  justify-content: center;
  text-align: center;
} */
.main-btn-not-active {
  border: none;
  border-image: 0;
}

.review-button {
  padding: 20px 80px;
  font-size: 18px;
}
@media (max-width: 400px) {
  .review-button {
    padding: 20px 20px;
    font-size: 18px;
  }
}
/* main text */
.main-text {
color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* special line */
.line {
  position: relative;
}

.line::after {
  content: "";
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #dfdfdf;
}
.slick-next {
  right: 25px !important;
}

.slick-prev {
  left: 25px !important;
}

.slick-prev {
  font-size: 0px;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block !important;
  width: 20px;
  height: 20px;
  padding: 0;
  left: 25px !important;
  transform: translate(0, 50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 9;
}
.slick-prev:before, .slick-next:before {
  /* font-family: 'slick'; */
  font-family: "Cairo";

  font-size: 30px !important;
  line-height: 1;
  opacity: .75;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 .slick-next {
  font-size: 0px;
  right: 25px !important;

  line-height: 0;
  position: absolute;
  top: 24%;
  display: block;
  width: 190px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #f90303;
  border: none;
  outline: none;
  background: transparent;
}
@media (max-width: 900px) {
  .line::after {
    content: none;
  }
}

/* profile */
.w-100 {
  width: 100%;
}
.text-align-inherit {
  text-align: inherit;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.list-profile {
  font-size: 16px;
  font-weight: 600;
  padding: 18px;
  border-radius: 8px;
  margin-bottom: 16px;
}
.list-profile-bg {
  background: #fafafa;
  font-size: 16px;
  font-weight: 600;
  padding: 18px;
  border-radius: 8px;
  margin-bottom: 16px;
}
.list-profile-active {
  font-size: 18px;
  font-weight: 600;
  padding: 18px;
  border-radius: 8px;
  margin-bottom: 16px;
}

/* slick */

/* .slick-active button { */
  /* background-color: #f5841f !important; */
  /* width: 30px !important;
  height: 6px !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input_focused:focus {
  border: none;
  outline: none;
}

/* test */
@media (min-width: 576px) {
  .slick-next {
    background: rgb(247, 0, 255);
  }
  .slick-next::before {
    display: block;
  }
}

.font-hvr {
  font-size: 20px;
  transition: all ease-out 500ms;
}

.font-hvr:hover {
  font-size: 22px;
}
.ft-slick__dots--custom {
  height: 8px;
  width: 14px;
  background-color: #e5e7e9;
  border-radius: 4px;
  position: relative;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -10px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
/* .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px; */
  /* height: 10px; */
  /* margin: 0 5px;
  padding: 0;
  cursor: pointer; */
  /* height: unset !important; */
  /* transition: width 0.3s ease-in-out; */
/* } */
/* .slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 10px;
  height: 10px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
} */
/* .slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
} */
/* .slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
} */
/* .slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 10px;
  height: 10px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
} */

.ft-slick__dots--custom {
  height: 8px;
  width: 14px;
  background-color: #e5e7e9;
  border-radius: 4px;
  position: relative;
}

.slick-dots li {
  width: 14px;
  margin: 0 2px;
  /* transition: width 0.3s ease-in-out; */
}
.slick-dots li button:before {
  /* font-family: 'slick'; */
  font-family: "Cairo";

  font-size: 13px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .slick-dots .slick-active { */
  /* width: 56px; */
  /* transition: width 0.3s ease-in-out; */
/* } */


.slick-dots .slick-active .ft-slick__dots--custom .loading {
  height: 8px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}

/* radio button */

/* .radio-border {
  border: 2px solid;
  width: 20px;
  height: 20px;
  border-image-source: linear-gradient(90.41deg, #f5841f 0.35%, #ed1c24 99.64%);
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.08);
  background: linear-gradient(90.41deg, #f5841f 0.35%, #ed1c24 99.64%),
    linear-gradient(0deg, #ffffff, #ffffff);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  border-radius: 50%;
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.complain {
  visibility: visible;
  opacity: 1;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.complain-hidden {
  visibility: hidden;
  opacity: 0;
  animation-name: fadeOut;
  animation-duration: 0.5s;
}
