.recommend {
  overflow: hidden;
  height: 400px;
}

/* space between */
.recommend .slick-list {
  margin: 8px;
}
.recommend .slick-slide {
  margin: 8px;
}

/* .recommend .slick-slider {
  width: 100%;
  overflow: hidden;
}
.recommend .slick-list {
  position: relative;
  display: block !important;
  width: 100%;
}
.recommend .slick-slide > div {
  width: auto;
  margin-right: 10px;
} */


/* arrows */
.slider-arrow .back,
.slider-arrow .next {
  width: 25px;
  height: 34px;
  background: #fb0202;
  color: #000;
  border-radius: 8px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.08);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-arrow .next {
  position: absolute;
  top: 50%;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-arrow .back {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 4px;
}
.slider-arrow .back {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* remove outline border */
/* .slick-slide {
  outline: none;
} */




