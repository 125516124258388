.Count_cart_items{
    position: absolute;
    top: -8px;
    right: -15px;
    background-color: #ff0000;
    color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    transform: scale(0.8);
    transform-origin: top right;
    transition: all 0.3s ease;
    z-index: -1;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 20px;
}
.textarea {
    width: 100%;
    border: none;
    outline: none;
    resize: none;
  }
  
  .textarea::placeholder {
    color: #8a8a8a;
    font-size: 20px;
    font-weight: 500;
  }



.cart_items{
    position: relative;
}

@media(max-width: 900px){
  .boxsubnav1{
    left: 0;
  }
} 
@media(max-width: 600px){
  .boxsubnav1{
    left: 0;
  }
}
.css-bknhxo-MuiButtonBase-root-MuiButton-root{
padding: 0px !important;

}
.radiusimg{
  border-radius: 5px;
 
}
/* .btnmain{
  background-image: linear-gradient(90deg, #DC1820 0%, #AF1016 100%) !important;

}
.btnmain:hover{
  background: #e2e2e2 !important;

}
.textmain{
  color: #ffffff !important;
}
.textmain:hover{
  color: red !important;

} */